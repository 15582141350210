@font-face {
  font-family: 'Inter Regular';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter Medium';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter Bold';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter ExtraBold';
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter Light';
  src: url('../fonts/Inter-Light.woff2') format('woff2'),
    url('../fonts/Inter-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Faktum Bold';
  src: url('../fonts/Faktum-Bold.woff2') format('woff2'),
    url('../fonts/Faktum-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Faktum SemiBold';
  src: url('../fonts/Faktum-SemiBold.woff2') format('woff2'),
    url('../fonts/Faktum-SemiBold.woff') format('woff');
}
